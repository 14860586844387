import { render, staticRenderFns } from "./fixtopic.vue?vue&type=template&id=5ebf4382&scoped=true&"
import script from "./fixtopic.vue?vue&type=script&lang=js&"
export * from "./fixtopic.vue?vue&type=script&lang=js&"
import style0 from "./fixtopic.vue?vue&type=style&index=0&id=5ebf4382&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ebf4382",
  null
  
)

export default component.exports